import React from 'react';
import Grid from "@material-ui/core/Grid";

const scoreAverage = (value) => {
    if (value || value === 0) {
        return Number(value).toLocaleString(undefined, {style: 'percent', minimumFractionDigits: 2});
    } else {
        return "unknown";
    }
}

const renderEntity = (entity, index) => {
    return (<Grid container justify="flex-end" alignItems="flex-end" key={Math.random()}>
        {index === 0 &&
        <React.Fragment>
            <Grid item xs={9}>Entities:</Grid>
            <Grid item xs={2}> {index === 0 && <span>Scores:</span>}</Grid>
        </React.Fragment>}
        <Grid item xs={9}>@{entity.entity}: {entity.value} </Grid>
        <Grid item xs={2}>{scoreAverage(entity.score)}</Grid>
    </Grid>)
}

const ConversationItemEntities = (props) => {

    return (
        <React.Fragment>
            {props.entities ?
                props.entities.map((entity, index) => {
                    return (renderEntity(entity, index))
                }) :
                <Grid container justify="flex-end" alignItems="flex-end">
                    <Grid item xs={8}>No entities</Grid>
                    <Grid item xs={3}/>
                </Grid>
            }
        </React.Fragment>
    );
}

export default ConversationItemEntities;