import React from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";

const MaterialSpinner = () => {
    return (
        <tbody className="MuiTableBody-root">
            <tr className="MuiTableRow-root MuiTableRow-hover" tabIndex="-1">
                <td className="MuiTableCell-root MuiTableCell-body text-center" colSpan="6">
                    <CircularProgress />
                </td>
            </tr>
        </tbody>
    );
}

export default MaterialSpinner;