import React from 'react';
import Grid from "@material-ui/core/Grid";

const scoreAverage = (value) => {
    if (value || value === 0) {
        return Number(value).toLocaleString(undefined, {style: 'percent', minimumFractionDigits: 2});
    } else {
        return "unknown";
    }
}
const renderIntent = (intent, index) => {
    return (<Grid container justify="flex-end" alignItems="flex-end" key={Math.random()}>
        {index === 0 &&
        <React.Fragment>
            <Grid item xs={9}>Intents:</Grid>
            <Grid item xs={2}> {index === 0 && <span>Scores:</span>}</Grid>
        </React.Fragment>}
        <Grid item xs={9}>#{intent.intent.replaceAll("_"," ")}</Grid>
        <Grid item xs={2}>{scoreAverage(intent.score)}</Grid>
    </Grid>)
}

const ConversationItemIntents = (props) => {
    return (
        <React.Fragment>
            {props.intents ?
                props.intents.slice(0, 3).map((intent, index) => {
                    return (renderIntent(intent, index))
                })
                :
                <Grid container justify="flex-end" alignItems="flex-end">
                    <Grid item xs={8}>No intents</Grid>
                    <Grid item xs={3}/>
                </Grid>
            }
        </React.Fragment>
    );
}

export default ConversationItemIntents;