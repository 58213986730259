import React, {useEffect, useState} from 'react';
import MaterialTable from "./MaterialTable/MaterialTable";
import Dialog from "@material-ui/core/Dialog";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";
import {MuiThemeProvider} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ConversationDetail from "./ConversationDetail/ConversationDetail";
import Slide from "@material-ui/core/Slide";
import AutocompleatableSelect from "../Select/AutocompleatableSelect";
import CoachingKangaroo from "../../api/CoachingKangaroo";
import TrainingToolTheme from "../TrainingToolTheme";
import ConversationStatusSelect from "../ConversationStatusSelect/ConversationStatusSelect";
import ConversationFeelingSelect from "../ConversationFeelingSelect/ConversationFeelingSelect";
import {ratingCodes} from "../MaterialTrainingTool/ConversationItem/AnswerRatingButtons/AnswerRatingButtons";
import TextField from "@material-ui/core/TextField";
import {isInternalTrainer} from "../../services/authService"
import {TIME_FROM, TIME_TO, formatDateToYyyyMmDd} from "../../utils/formattingUtils";
import useAlert from "../hooks/useAlert";

const statuses = [
    "NOT_RATED",
    "RATED",
    "TRAINING_NEEDED",
    "TRAINED",
    "RESOLVED",
];

const feelings = [
    "NONE",
    "GOOD",
    "BAD",
];

const platforms = [
    'WEB_PUBLIC',
    'MOBILE_APP',
    'IM',
    'PHONE',
    'GOOGLE_ASSISTANT'
];

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const MaterialTrainingTool = (props) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [conversationPreview, setConversationPreview] = useState(null);
    const [dirtyConversation, setDirtyConversation] = useState(null);
    const [inputSessionID, setInputSessionID] = useState("");
    const [dateFrom, setDateFrom] = useState(() => {
        const d = new Date();
        d.setDate(d.getDate() - 1);
        return d;
    });
    const [dateTo, setDateTo] = useState(new Date());
    const [intents, setIntents] = useState([]);
    const [entities, setEntities] = useState([]);
    const [context, setContext] = useState(props.context);
    const [contextValues, setContextValues] = useState([]);
    const [entityValues, setEntityValues] = useState([]);
    const [selectedIntents, setSelectedIntents] = useState([]);
    const [selectedEntities, setSelectedEntities] = useState([]);
    const [selectedEntityValues, setSelectedEntityValues] = useState([]);
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [selectedFeelings, setSelectedFeelings] = useState([]);
    const [selectedRatings, setSelectedRatings] = useState([]);
    const [selectedPlatforms, setSelectedPlatforms] = useState([]);
    const [selectedContext, setSelectedContext] = useState([]);
    const [selectedContextValues, setSelectedContextValues] = useState([]);
    const [filter, setFilter] = useState(null);
    const {
        Alert,
        status,
        showError,
    } = useAlert();
    const {project} = props;

    useEffect(() => {
        getEntities();
        getIntents();
        filterSubmit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project]);

    useEffect(() => {
        setSelectedContext([])
        setSelectedContextValues([])
        setSelectedIntents([]);
        setSelectedEntities([]);
        setSelectedEntityValues([]);
        getEntities();
        getIntents();
        if (isInternalTrainer(project) && dateTo > dateFrom) {
            getContext()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateFrom, dateTo, project]);

    useEffect(() => {
        if (selectedEntities.length > 0) {
            CoachingKangaroo.get("/api/training/v1/"
                + encodeURIComponent(project)
                + "/entity-values?entities=" + selectedEntities
                + "&at_from=" + formatDateToYyyyMmDd(dateFrom) + TIME_FROM
                + "&at_to=" + formatDateToYyyyMmDd(dateTo) + TIME_TO)
                .then(response => {
                    setEntityValues(response.data);
                })
                .catch(error => {
                    console.error("Error while fetching data", error)
                });
        } else {
            setEntityValues([]);
            setSelectedEntityValues([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedEntities]);

    useEffect(() => {
        if (selectedContext.length > 0) {
            CoachingKangaroo.get("/api/training/v1/"
                + encodeURIComponent(project)
                + "/context-values?context=" + selectedContext
                + "&at_from=" + formatDateToYyyyMmDd(dateFrom) + TIME_FROM
                + "&at_to=" + formatDateToYyyyMmDd(dateTo) + TIME_TO)
                .then(response => {
                    setContextValues(response.data);
                })
                .catch(error => {
                    console.error("Error while fetching data", error)
                });
        } else {
            setContextValues([]);
            setSelectedContextValues([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedContext]);

    useEffect(() => {
        function handleEscapeKey(event) {
            if (event.code === 'Escape') {
                handleDialogClose();
            }
        }

        document.addEventListener('keydown', handleEscapeKey)
        return () => document.removeEventListener('keydown', handleEscapeKey)
    }, [])

    const rowClicked = (row) => {
        setConversationPreview(row);
        setDialogOpen(true);
    }

    const getContext = () => {
        CoachingKangaroo.get("/api/training/v1/"
            + encodeURIComponent(project)
            + "/context"
            + "?at_from=" + formatDateToYyyyMmDd(dateFrom) + TIME_FROM
            + "&at_to=" + formatDateToYyyyMmDd(dateTo) + TIME_TO)
            .then(response => {
                const result = response.data
                if (result) {
                    setContext(result);
                }
            })
            .catch(error => {
                showError("Error while fetching context", error)
                setContext([]);
            })
    }

    const getIntents = () => {
        CoachingKangaroo.get("/api/training/v1/"
            + encodeURIComponent(project)
            + "/intents"
            + "?at_from=" + formatDateToYyyyMmDd(dateFrom) + TIME_FROM
            + "&at_to=" + formatDateToYyyyMmDd(dateTo) + TIME_TO)
            .then(response => {
                setIntents(response.data);
            })
            .catch(error => {
                setIntents([]);
                console.error("Error while fetching data", error)
            });
    }

    const getEntities = () => {
        CoachingKangaroo.get("/api/training/v1/"
            + encodeURIComponent(project)
            + "/entities"
            + "?at_from=" + formatDateToYyyyMmDd(dateFrom) + TIME_FROM
            + "&at_to=" + formatDateToYyyyMmDd(dateTo) + TIME_TO)
            .then(response => {
                const result = response.data
                if (result) {
                    setEntities(result);
                }
            })
            .catch(error => {
                setEntities([]);
                console.error("Error while fetching data", error)
            });
    }

    const filterSubmit = () => {
        setFilter({
            dateFrom: dateFrom,
            dateTo: dateTo,
            selectedIntents: selectedIntents,
            selectedEntities: selectedEntities,
            selectedEntityValues: selectedEntityValues,
            selectedStatuses: selectedStatuses,
            selectedFeelings: selectedFeelings,
            selectedRatings: selectedRatings,
            inputSessionID: inputSessionID,
            selectedPlatforms: selectedPlatforms,
            selectedContext: selectedContext,
            selectedContextValues: selectedContextValues
        });
    }

    const filterReset = () => {
        setDateFrom(null);
        setDateTo(null);
        setSelectedIntents([]);
        setSelectedEntities([]);
        setSelectedEntityValues([]);
        setSelectedStatuses([]);
        setSelectedFeelings([]);
        setSelectedRatings([]);
        setInputSessionID("");
        setSelectedPlatforms([]);
        setSelectedContext([]);
        setSelectedContextValues([]);
    }

    const handleDialogClose = () => {
        setDialogOpen(false);
    }

    const handleSessionIDChange = (event) => {
        setInputSessionID(event.target.value.trim());
    }

    return (
        <div>
            {status ? <Alert/> : null}
            <MuiThemeProvider theme={TrainingToolTheme}>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid container item xs={12} spacing={3}>
                        <Grid container item xs={3}>
                            <MuiPickersUtilsProvider utils={LuxonUtils}>
                                <KeyboardDatePicker
                                    label="Date From"
                                    value={dateFrom}
                                    onChange={(newValue) => setDateFrom(newValue)}
                                    clearable
                                    disableFuture
                                    format="dd.MM.yyyy"
                                    autoOk
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid container item xs={3}>
                            <MuiPickersUtilsProvider utils={LuxonUtils}>
                                <KeyboardDatePicker
                                    label="Date To"
                                    value={dateTo}
                                    onChange={(newValue) => setDateTo(newValue)}
                                    clearable
                                    disableFuture
                                    format="dd.MM.yyyy"
                                    autoOk
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid container item xs={3}>
                            <AutocompleatableSelect
                                label="Platforms"
                                options={platforms}
                                selectedOptions={selectedPlatforms}
                                setSelectedOptions={setSelectedPlatforms}
                            />
                        </Grid>
                        <Grid container alignItems="center" item xs={3}>
                            <Grid style={{height: '100%'}} item xs>
                                <Button
                                    style={{height: '100%'}}
                                    size="large"
                                    variant="contained"
                                    onClick={filterSubmit}
                                    color="primary">Filter</Button>
                            </Grid>
                            <Grid style={{height: '100%'}} item xs>
                                <Button
                                    style={{height: '100%'}}
                                    size="large"
                                    variant="contained"
                                    onClick={filterReset}>Reset</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={3}>
                        <Grid container item xs={3}>
                            <ConversationStatusSelect
                                statuses={statuses}
                                selectedStatuses={selectedStatuses}
                                setSelectedStatuses={setSelectedStatuses}
                            />
                        </Grid>
                        <Grid container item xs={3}>
                            <ConversationFeelingSelect
                                feelings={feelings}
                                selectedFeelings={selectedFeelings}
                                setSelectedFeelings={setSelectedFeelings}
                            />
                        </Grid>
                        <Grid container item xs={3}>
                            <TextField
                                label="Session ID"
                                style={{width: '100%'}}
                                onChange={handleSessionIDChange}
                                value={inputSessionID}
                            />
                        </Grid>
                        <Grid container item xs={3}>
                            <AutocompleatableSelect
                                label="Answer rating"
                                options={ratingCodes.map(it => it.code)}
                                selectedOptions={selectedRatings}
                                setSelectedOptions={setSelectedRatings}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={3}>
                        <Grid container item xs={3}>
                            <AutocompleatableSelect
                                label="Entities"
                                options={entities}
                                selectedOptions={selectedEntities}
                                setSelectedOptions={setSelectedEntities}
                            />
                        </Grid>
                        <Grid container item xs={3}>
                            <AutocompleatableSelect
                                label="Entity values"
                                noOptionsText="No entities are selected"
                                options={entityValues}
                                selectedOptions={selectedEntityValues}
                                setSelectedOptions={setSelectedEntityValues}
                            />
                        </Grid>
                        <Grid container item xs={3}>
                            <AutocompleatableSelect
                                label="Intents"
                                options={intents}
                                selectedOptions={selectedIntents}
                                setSelectedOptions={setSelectedIntents}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={3}>
                        <Grid container item xs={3}>
                            <AutocompleatableSelect
                                label="Context"
                                options={context}
                                selectedOptions={selectedContext}
                                setSelectedOptions={setSelectedContext}
                            />
                        </Grid>
                        <Grid container item xs={3}>
                            <AutocompleatableSelect
                                label="Context values"
                                options={contextValues}
                                noOptionsText="No context is selected"
                                selectedOptions={selectedContextValues}
                                setSelectedOptions={setSelectedContextValues}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                        <MaterialTable
                            project={project}
                            filter={filter}
                            dirtyConversation={dirtyConversation}
                            onClick={rowClicked}
                        />
                    </Grid>
                    <Dialog
                        open={dialogOpen}
                        onBackdropClick={handleDialogClose}
                        TransitionComponent={Transition}
                        fullWidth={true}
                        maxWidth="lg"
                        aria-labelledby="customized-dialog-title"
                    >
                        <ConversationDetail
                            project={project}
                            handleDialogClose={handleDialogClose}
                            conversationPreview={conversationPreview}
                            setDirtyConversation={setDirtyConversation}
                        />
                    </Dialog>
                </Grid>
            </MuiThemeProvider>
        </div>
    );
}

export default MaterialTrainingTool;
