import React, { useState } from 'react';
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const MaterialProjectSelect = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);

    const openMenu = (event) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
    }

    return (
        <React.Fragment>
            <span className="main-title-user-project">
                <Button variant="contained" onClick={openMenu}>{props.selectedProjectName}</Button>
            </span>
            <Menu
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
                PaperProps={{
                    style: {
                        transform: 'translateX(10%) translateY(15%)',
                    }
                }}>{props.projects ?
                    props.projects.map((project) => (
                        <MenuItem
                            key={project.id}
                            value={project.id}
                            onClick={() => {
                                setMenuOpen(false);
                                props.handleChange(project.name);
                            }} >{project.name}
                        </MenuItem>
                    )
                    )
                    : null
                }
            </Menu>
        </React.Fragment>
    );
}

export default MaterialProjectSelect;