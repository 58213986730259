import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFrown, faMeh, faSmile} from "@fortawesome/free-regular-svg-icons";
import red from "@material-ui/core/colors/red";
import yellow from "@material-ui/core/colors/yellow";
import green from "@material-ui/core/colors/green";

const renderCell = (column, value) => {
    if (column.id === "id") {
        return renderCellId(value);
    } else if (column.id === "text") {
        return renderCellText(value);
    } else if (column.id === "answer-count") {
        return value;
    } else if (column.id === "first-score-average") {
        return renderCellFirstScoreAverage(value);
    } else if (column.id === "started-at") {
        return renderCellDate(value);
    } else if (column.id === "feeling") {
        return renderCellFeeling(value);
    } else if (column.id === "trained") {
        return renderCellTrained(value);
    } else if (column.id === "status") {
        return renderCellStatus(value);
    } else {
        return value;
    }
}

const renderCellId = (value) => {
    if (value) {
        return value.slice(0, 9) + "...";
    } else {
        return "";
    }
}

const renderCellText = (value) => {
    if (value) {
        return (<strong>{value}</strong>);
    } else {
        return "Welcome message";
    }
}

const renderCellFirstScoreAverage = (value) => {
    if (value) {
        return Number(value).toLocaleString(undefined, {style: 'percent', minimumFractionDigits: 2});
    } else {
        return "";
    }
}

const renderCellDate = (value) => {
    if (value) {
        return new Intl.DateTimeFormat("cs-CZ", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
        }).format(Date.parse(value));
    } else {
        return "";
    }
}

const renderCellFeeling = (value) => {
    if (value === "NOT_RATED") {
        return (<FontAwesomeIcon icon={faMeh} style={{fontSize: "1.2em"}}/>);
    } else if (value === "NONE") {
        return (<FontAwesomeIcon icon={faMeh} style={{color: yellow[900], fontSize: "1.2em"}}/>);
    } else if (value === "GOOD") {
        return (<FontAwesomeIcon icon={faSmile} style={{color: green[800], fontSize: "1.2em"}}/>);
    } else if (value === "BAD") {
        return (<FontAwesomeIcon icon={faFrown} style={{color: red[800], fontSize: "1.2em"}}/>);
    }
}

const renderCellStatus = (value) => {
    if (value === "TRAINED") {
        return "Trained";
    } else if (value === "NOT_RATED") {
        return "Not Rated";
    } else if (value === "RATED") {
        return "Rated";
    } else if (value === "TRAINING_NEEDED") {
        return "Training Needed";
    } else if (value === "RESOLVED") {
        return "Resolved";
    } else {
        return "-";
    }
}

const renderCellTrained = (value) => {
    if (value) {
        return "Yes";
    } else {
        return "No";
    }

    // if (this.props.incorrectResolved === this.props.incorrectTotal) {
}

const MaterialTableRow = (props) => {
    return (
        <TableRow hover tabIndex={-1} key={props.row.id} onClick={props.onClick}>
            {props.columns.map((column) => {
                const value = props.row[column.id];
                return (
                    <TableCell key={column.id} align={column.align}>
                        {renderCell(column, value)}
                    </TableCell>
                );
            })}
        </TableRow>
    );
}

export default MaterialTableRow;