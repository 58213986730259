import React, {useEffect} from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Button from "@material-ui/core/Button";
import CoachingKangaroo from "../../../../api/CoachingKangaroo";
import TrainingToolTheme from "../../../TrainingToolTheme";
import useAlert from "../../../hooks/useAlert";
import {isTrainer} from "../../../../services/authService";
import * as conversationService from "../../../../services/conversationService";
import CircularProgress from "@material-ui/core/CircularProgress";

const conversationStatuses = [
    "Not Rated",
    "Rated",
    "Training needed",
    "Trained",
    "Resolved",
];

const conversationStatusCodes = [
    {id: 0, code: "NOT_RATED", label: "Not Rated", color: "gray"},
    {id: 1, code: "RATED", label: "Rated", color: "yellow"},
    {id: 2, code: "TRAINING_NEEDED", label: "Training needed", color: "red"},
    {id: 3, code: "TRAINED", label: "Trained", color: "yellow"},
    {id: 4, code: "RESOLVED", label: "Resolved", color: "green"},
];

const greenBtn = TrainingToolTheme.palette.trainingGreen;
const redBtn = TrainingToolTheme.palette.trainingRed;
const yellowBtn = TrainingToolTheme.palette.trainingYellow;
const blueBtn = TrainingToolTheme.palette.trainingBlue;
const grayBtn = TrainingToolTheme.palette.trainingGray;

const ConversationStatusButtons = (props) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [selectedConversationStatusCode, setSelectedConversationStatusCode] = React.useState(conversationStatusCodes[0]);
    const {
        Alert,
        status,
        showError,
    } = useAlert();
    const [dataFetched, setDataFetched] = React.useState(true);
    const handleClick = (index) => {
        handleMenuItemClick(index);
    };

    const handleMenuItemClick = (index) => {
        let conversationStatusCode = conversationStatusCodeByConversationStatus(conversationStatuses[index]);

        setSelectedIndex(index);
        setSelectedConversationStatusCode(conversationStatusCode);
        setOpen(false);

        changeAnswer(conversationStatusCode.code);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const changeAnswer = (conversationStatusCodeCode) => {
        let requestBody = {status: conversationStatusCodeCode};
        setDataFetched(false);
        // /{clientId}/conversation/{conversationId}
        CoachingKangaroo.put("/api/training/v1/"
            + encodeURIComponent(props.project)
            + "/conversation/"
            + props.conversationId,
            JSON.stringify(requestBody)).then(() => {
            conversationService.getConversation(props.project, props.conversationId)
                .then(response => {
                    const result = response.data
                    if (result) {
                        props.setDirtyConversation(result);
                    }
                }).catch(error => {
                showError(error);
            })
        }).catch(error => {
            showError(error);
        }).finally(()=>{
            setDataFetched(true);
        });
    }

    useEffect(() => {
        if (props.status) {
            let conversationStatusCode = conversationStatusCodes
                .filter(conversationStatusCode => conversationStatusCode.code === props.status)
                .map(conversationStatusCode => {
                    return conversationStatusCode;
                })[0];
            setSelectedConversationStatusCode(conversationStatusCode);
            setSelectedIndex(conversationStatusCode.id);
        }
    }, [props.status])

    const conversationStatusCodeByConversationStatus = (conversationStatus) => {
        return conversationStatusCodes
            .filter(conversationStatusCode => conversationStatusCode.label === conversationStatus)
            .map(conversationStatusCode => {
                return conversationStatusCode;
            })[0]
    };

    return (
        <React.Fragment>
            {status ? <Alert/> : null}
            <Grid container justify="flex-end">
                <Grid item>
                    <ButtonGroup
                        {...!isTrainer(props.project) && {disabled: true}}
                        onClick={() => isTrainer(props.project) ? undefined: showError("You do not have enough permissions to edit conversations.")}
                        variant="contained"
                        ref={anchorRef}
                        aria-label="split button">
                        <Button onClick={() => handleClick(selectedIndex)}
                                style={selectedConversationStatusCode.color === "green" ? greenBtn :
                                    selectedConversationStatusCode.color === "red" ? redBtn :
                                        selectedConversationStatusCode.color === "yellow" ? yellowBtn :
                                            selectedConversationStatusCode.color === "blue" ? blueBtn :
                                                grayBtn}
                        >{conversationStatuses[selectedIndex]}</Button>
                        <Button
                            size="small"
                            aria-controls={open ? 'split-button-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-label="Select Training Status"
                            aria-haspopup="menu"
                            onClick={handleToggle}
                        >
                            {!dataFetched ? <CircularProgress size={20}/> : <ArrowDropDownIcon/>}
                        </Button>
                    </ButtonGroup>
                    <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal
                            style={{zIndex: 1}}>
                        {({TransitionProps, placement}) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList id="split-button-menu">
                                            {conversationStatuses.map((conversationStatus, index) => (
                                                <MenuItem
                                                    key={index}
                                                    // disabled={index === 0}
                                                    selected={index === selectedIndex}
                                                    onClick={() => handleMenuItemClick(index)}
                                                >
                                                    {conversationStatus}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default ConversationStatusButtons;