import React, {useLayoutEffect, useState} from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./App.scss";
import "./Mobile.scss";
import MaterialTrainingTool from "./components/MaterialTrainingTool/MaterialTrainingTool";
import MaterialProjectSelect from "./components/MaterialProjectSelect/MaterialProjectSelect";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {version} from '../package.json';
import {ReactKeycloakProvider} from '@react-keycloak/web'
import User from "./models/User";
import keycloak from './api/Keycloak';
import Alert from "@material-ui/lab/Alert";
import TalkingCamel from "./api/TalkingCamel";

const resfreshAccessTokenInterval = 1770; //s

const useStyles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});

const App = () => {
  const [, setWindowWidth] = useState(0);
  const [, setWindowHeight] = useState(0);
  const [projects, setProjects] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [user, setUser] = useState(new User());
  const [anchorEl, setAnchorEl] = useState();
  const [menuOpen, setMenuOpen] = useState(false);
  const [loginError, setError] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const classes = useStyles;

  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  keycloak.onTokenExpired = () => {
    keycloak.updateToken(resfreshAccessTokenInterval).success((refreshed) => {
      if (refreshed) {
        setAccessToken(keycloak.token);
      } else {
        setError("Failed to refresh token");
      }
    }).error(() => {
      setError("Failed to refresh token");
    });
  };


  useLayoutEffect(() => {
    window.addEventListener("resize", resizeWindow);
    resizeWindow();
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  const handleOnEvent = async (event, error) => {
    if (event === 'onAuthSuccess') {
      if (keycloak.authenticated) {
        setAccessToken(keycloak.token);
        const profile = await keycloak.loadUserProfile();
        const context = keycloak.tokenParsed.context !== undefined ? keycloak.tokenParsed.context.split(";") : [];
        const clientRoles = keycloak.realmAccess.roles.filter(role =>
          role.startsWith("ADMIN")
          || role.startsWith("TRAINING")
        );
        if (clientRoles.length === 0) {
          setError("User has NO role assigned");
        } else if (isSingleMode()) {
          setSingleModeProject();
        } else {
          getProjects(clientRoles);
        }
        let keycloakUser = new User(profile.username, profile.firstName, profile.lastName, profile.email, clientRoles, context);
        setUser(keycloakUser);
      }
    }
  }

  const isSingleMode = () => {
    if (!window.g_viaSingleMode?.startsWith("%")) return Boolean(window.g_viaSingleMode) !== false // it's a string
    else return process.env.REACT_APP_SINGLE_MODE === true
  }

  const setSingleModeProject = () => {
    let projectId = !window.g_viaSingleModeProjectId?.startsWith("%") ? window.g_viaSingleModeProjectId : process.env.REACT_APP_PROJECT_ID
    let projectName = !window.g_viaSingleModeProjectName?.startsWith("%") ? window.g_viaSingleModeProjectName : process.env.REACT_APP_PROJECT_NAME

    setProjects([{id: projectId, name: projectName}])
    setProjectName(projectName)
  }

  const getProjects = (roles) => {
    TalkingCamel.post("/api/v1/projects",
      roles,
      {
        headers: {
          Authorization: "Bearer " + keycloak.token,
        },
      })
      .then(response => {
        setProjects(response.data);
        setProjectName(response.data[0].name);
      })
      .catch(error => {
        setError("Error while fetching data");
      });
  }

  const switchLoggedInState = () => {
    setAnchorEl();
    setProjects([]);
    setProjectName("");
    setUser(null);
    setMenuOpen(false);
    setWindowWidth(0);
    setWindowHeight(0);
    keycloak.logout();
  };

  const recordButtonPosition = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  }

  const closeMenu = () => {
    setMenuOpen(false);
  }

  const handleProjectChange = (name) => {
    setProjectName(name);
  }
  return (
    <ReactKeycloakProvider
      className={classes.root}
      authClient={keycloak}
      initOptions={
        {
          onLoad: 'login-required',
          checkLoginIframe: false
        }
      }
      onEvent={(event, error) => handleOnEvent(event, error)}>
      <div className={"App " + (keycloak.authenticated ? "loggedIn" : "d")}>
        <div className="App-header">
          <div className="main-title-logo">
            <img
              alt="Main Logo"
              id="logo-header"
              src="/Logo-transparent.png"
            />
          </div>
          {user ?
            <div className="main-title-user">
              <MaterialProjectSelect
                projects={projects}
                selectedProjectName={projectName}
                handleChange={handleProjectChange}
              />
              <span className="main-title-user-avatar" onClick={recordButtonPosition}>
                <span className="main-title-user-avatar-first">
                  {user.firstname.slice(0, 1)}
                </span>
                <span className="main-title-user-avatar-last">
                  {user.lastname.slice(0, 1)}
                </span>
              </span>
                            {anchorEl ?
                                <Menu
                                    anchorEl={anchorEl}
                                    open={menuOpen}
                                    onClose={closeMenu}
                                    PaperProps={{
                                        style: {
                                            transform: 'translateX(-20%) translateY(100%)',
                                        }
                                    }}
                                >
                                    <MenuItem onClick={switchLoggedInState}> Logout </MenuItem>
                                </Menu>
                                :
                                null
                            }
                        </div>
                        : null
                    }
                    <div className="main-title">
                        <h1>Training Tool</h1>
                    </div>
                    {loginError.length > 0 && <Alert severity="error">{loginError}</Alert>}
                </div>
                {projectName ?
                    <div className="container">
                        <div className="App-body">
                            <MaterialTrainingTool
                                accessToken={accessToken}
                                project={projectName}
                                context={user.context}
                            />
                        </div>
                    </div>
                    : null
                }
                <div className="App-footer">
                    <div className="col">Made by AddAI team, version: {version}</div>
                </div>
            </div>
        </ReactKeycloakProvider>
    );
}

export default App;
