import axios from "axios";
import Keycloak  from "./Keycloak";

const axiosClient =  axios.create({
  baseURL: (!window.g_viaBaseUrl?.startsWith("%") ? window.g_viaBaseUrl : null) ?? process.env.REACT_APP_BASE_URL ?? "/ck",
  timeout: 300000,
});

axiosClient.interceptors.request.use(
    config => {
      config.headers['Authorization'] = `Bearer ${Keycloak.token}`;
      config.headers['Content-Type'] = `application/json`;
      return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export default axiosClient;
