import React, {useEffect} from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import CoachingKangaroo from "../../../../api/CoachingKangaroo";
import TrainingToolTheme from "../../../TrainingToolTheme";
import Popover from "@material-ui/core/Popover";
import useAlert from "../../../hooks/useAlert";
import {isTrainer} from "../../../../services/authService";
import CircularProgress from "@material-ui/core/CircularProgress";

const trainingStatuses = [
    "None",
    "Trained",
    "Not Trained",
];

const trainingStatusCodes = [
    {id: 0, code: "NONE", label: "None", color: "gray"},
    {id: 1, code: "TRAINED", label: "Trained", color: "green"},
    {id: 2, code: "NOT_TRAINED", label: "Not Trained", color: "yellow"},
];

const greenBtn = TrainingToolTheme.palette.trainingGreen;
const redBtn = TrainingToolTheme.palette.trainingRed;
const yellowBtn = TrainingToolTheme.palette.trainingYellow;
const blueBtn = TrainingToolTheme.palette.trainingBlue;
const grayBtn = TrainingToolTheme.palette.trainingGray;

const AnswerStatusButtons = (props) => {
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [selectedTrainingStatusCode, setSelectedTrainingStatusCode] = React.useState(trainingStatusCodes[0]);
    const {
        Alert,
        status,
        showError,
    } = useAlert();
    const [dataFetched, setDataFetched] = React.useState(true);
    const handleClick = (index) => {
        handleMenuItemClick(index);
    };

    const handleMenuItemClick = (index) => {
        let trainingStatusCode = trainingStatusCodeByTrainingStatus(trainingStatuses[index]);

        setSelectedIndex(index);
        setSelectedTrainingStatusCode(trainingStatusCode);

        setAnchorEl(null);
        setOpen(false);

        changeAnswer(trainingStatusCode.code);
    };

    const handleToggle = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        setAnchorEl(null);
        setOpen(false);
    };

    const changeAnswer = (trainingStatusCodeCode) => {
        let requestBody = {status: trainingStatusCodeCode};
        setDataFetched(false);
        // /{clientId}/conversation/{conversationId}/answer/{answerId}
        CoachingKangaroo.put("/api/training/v1/"
            + encodeURIComponent(props.project)
            + "/conversation/"
            + props.conversationId
            + "/answer/"
            + props.answerId,
            JSON.stringify(requestBody))
            .catch(error => {
                showError(error);
            }).finally(() => {
            setDataFetched(true);
        });
    }

    useEffect(() => {
        if (props.status) {
            let trainingStatusCode = trainingStatusCodes
                .filter(trainingStatusCode => trainingStatusCode.code === props.status)
                .map(trainingStatusCode => {
                    return trainingStatusCode;
                })[0];
            setSelectedTrainingStatusCode(trainingStatusCode);
            setSelectedIndex(trainingStatusCode.id);
        }
    }, [props.status])

    const trainingStatusCodeByTrainingStatus = (trainingStatus) => {
        return trainingStatusCodes
            .filter(trainingStatusCode => trainingStatusCode.label === trainingStatus)
            .map(trainingStatusCode => {
                return trainingStatusCode;
            })[0]
    };

    return (
        <React.Fragment>
            {status ? <Alert/> : null}
            <Grid container justify="flex-end">
                <Grid item>
                    {selectedTrainingStatusCode.code === "NONE" ? "Training status NOT set" : null}
                </Grid>
                <Grid item>
                    <ButtonGroup
                        {...!isTrainer(props.project) && {disabled: true}}
                        onClick={() => isTrainer(props.project) ? undefined : showError("You do not have enough permissions to edit conversations.")}
                        variant="contained"
                        aria-label="split button">
                        {selectedIndex === 0 ?
                            <Button onClick={() => handleClick(selectedIndex)}
                                    style={grayBtn}>{trainingStatuses[selectedIndex]}</Button>
                            :
                            <Button onClick={() => handleClick(selectedIndex)}
                                    style={selectedTrainingStatusCode.color === "green" ? greenBtn :
                                        selectedTrainingStatusCode.color === "red" ? redBtn :
                                            selectedTrainingStatusCode.color === "yellow" ? yellowBtn :
                                                selectedTrainingStatusCode.color === "blue" ? blueBtn :
                                                    grayBtn}
                            >{trainingStatuses[selectedIndex]}</Button>
                        }
                        <Button
                            size="small"
                            aria-controls={open ? 'split-button-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-label="Select Training Status"
                            aria-haspopup="menu"
                            onClick={handleToggle}
                        >
                            {!dataFetched ? <CircularProgress size={20}/> : <ArrowDropDownIcon/>}
                        </Button>
                    </ButtonGroup>
                    <Popover
                        id={open ? 'simple-popover' : undefined}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu">
                                    {trainingStatuses.map((trainingStatus, index) => (
                                        <MenuItem
                                            key={index}
                                            // disabled={index === 0}
                                            selected={index === selectedIndex}
                                            onClick={() => handleMenuItemClick(index)}
                                        >
                                            {trainingStatus}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Popover>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default AnswerStatusButtons;