import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import ConversationItemResponseDetails from "../ConversationItemResponseDetails/ConversationItemResponseDetails";

const useStyles = makeStyles((theme) => ({
    paperRight: {
        padding: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(),
    },
    paperRightOptions: {
        padding: 3,
        marginBottom: 2,
        maxWidth: 250,
    },
}));

const ConversationItemResponse = (props) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            {props.messages.map((message, index) =>
                <ConversationItemResponseDetails
                    key={index}
                    inputType={props.inputType}
                    message={message}
                    styles={classes}
                />)
            }
        </React.Fragment>
    );
}

export default ConversationItemResponse;