import React, {useEffect} from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import CoachingKangaroo from "../../../../api/CoachingKangaroo";
import TrainingToolTheme from "../../../TrainingToolTheme";
import Popover from "@material-ui/core/Popover";
import useAlert from "../../../hooks/useAlert";
import {isTrainer} from "../../../../services/authService";
import CircularProgress from "@material-ui/core/CircularProgress";

const ratings = [
    "None",
    "Correct",
    "False Positive",
    "Dialogue Issue",
    "Did Not Understand",
    "Out of Scope",
    "Not Clear",
    "More Intents",
    "Typo",
    "New Intent Candidate",
    "Irrelevant Candidate",
];

export const ratingCodes = [
    {id: 0, code: "NONE", label: "None", color: "gray"},
    {id: 1, code: "CORRECT", label: "Correct", color: "green"},
    {id: 2, code: "FALSE_POSITIVE", label: "False Positive", color: "red"},
    {id: 3, code: "DIALOGUE_ISSUE", label: "Dialogue Issue", color: "red"},
    {id: 4, code: "DID_NOT_UNDERSTAND", label: "Did Not Understand", color: "red"},
    {id: 5, code: "OUT_OF_SCOPE", label: "Out of Scope", color: "blue"},
    {id: 6, code: "NOT_CLEAR", label: "Not Clear", color: "yellow"},
    {id: 7, code: "MORE_INTENTS", label: "More Intents", color: "yellow"},
    {id: 8, code: "TYPO", label: "Typo", color: "yellow"},
    {id: 9, code: "NEW_INTENT_CANDIDATE", label: "New Intent Candidate", color: "yellow"},
    {id: 10, code: "IRRELEVANT_CANDIDATE", label: "Irrelevant Candidate", color: "yellow"},
];

const greenBtn = TrainingToolTheme.palette.trainingGreen;
const redBtn = TrainingToolTheme.palette.trainingRed;
const yellowBtn = TrainingToolTheme.palette.trainingYellow;
const blueBtn = TrainingToolTheme.palette.trainingBlue;
const grayBtn = TrainingToolTheme.palette.trainingGray;

export const AnswerRatingButtons = (props) => {
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [selectedRatingCode, setSelectedRatingCode] = React.useState(ratingCodes[0]);
    const {
        Alert,
        status,
        showError,
    } = useAlert();
    const [dataFetched, setDataFetched] = React.useState(true);

    const handleClick = (index) => {
        handleMenuItemClick(index);
    };

    const handleMenuItemClick = (index) => {
        let ratingCode = ratingCodeByRating(ratings[index]);

        setSelectedIndex(index);
        setSelectedRatingCode(ratingCode);

        setAnchorEl(null);
        setOpen(false);

        changeAnswer(ratingCode.code);
    };

    const handleToggle = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    const changeAnswer = (ratingCodeCode) => {
        let requestBody = {rating: ratingCodeCode};
        setDataFetched(false);
        // /{clientId}/conversation/{conversationId}/answer/{answerId}
        CoachingKangaroo.put("/api/training/v1/"
            + encodeURIComponent(props.project)
            + "/conversation/"
            + props.conversationId
            + "/answer/"
            + props.answerId,
            JSON.stringify(requestBody))
            .catch(error => {
                showError(error)
            }).finally(()=>{
                setDataFetched(true);
        });
    }

    useEffect(() => {
        if (props.rating) {
            let ratingCode = ratingCodes
                .filter(ratingCode => ratingCode.code === props.rating)
                .map(ratingCode => {
                    return ratingCode;
                })[0];
            setSelectedRatingCode(ratingCode);
            setSelectedIndex(ratingCode.id);
        }
    }, [props.rating])

    const ratingCodeByRating = (rating) => {
        return ratingCodes
            .filter(ratingCode => ratingCode.label === rating)
            .map(ratingCode => {
                return ratingCode;
            })[0]
    };

    return (
        <React.Fragment>
            {status ? <Alert/> : null}
            <Grid container justifyContent="flex-end">
                <Grid item>
                    {selectedRatingCode.code === "NONE" ? "Answer NOT Rated" : null}
                </Grid>
                <Grid item>
                    <ButtonGroup
                        {...!isTrainer(props.project) && {disabled: true}}
                        onClick={() => isTrainer(props.project) ? undefined : showError("You do not have enough permissions to edit conversations.")}
                        variant="contained"
                        aria-label="split button">
                        {selectedIndex === 0 ?
                            <Button onClick={() => handleClick(selectedIndex)}
                                    style={grayBtn}>{ratings[selectedIndex]}</Button>
                            :
                            <Button onClick={() => handleClick(selectedIndex)}
                                    style={selectedRatingCode.color === "green" ? greenBtn :
                                        selectedRatingCode.color === "red" ? redBtn :
                                            selectedRatingCode.color === "yellow" ? yellowBtn :
                                                selectedRatingCode.color === "blue" ? blueBtn :
                                                    grayBtn}
                            >{ratings[selectedIndex]}</Button>
                        }
                        <Button
                            size="small"
                            aria-controls={open ? 'split-button-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-label="Select Answer Rating"
                            aria-haspopup="menu"
                            onClick={handleToggle}
                        >
                            {!dataFetched ? <CircularProgress size={20}/> : <ArrowDropDownIcon/>}
                        </Button>
                    </ButtonGroup>
                    <Popover
                        id={open ? 'simple-popover' : undefined}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu">
                                    {ratings.map((rating, index) => (
                                        <MenuItem
                                            key={index}
                                            // disabled={index === 0}
                                            selected={index === selectedIndex}
                                            onClick={() => handleMenuItemClick(index)}
                                        >
                                            {rating}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Popover>
                </Grid>
            </Grid>

        </React.Fragment>
    );
}

