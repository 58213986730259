import React, {useEffect, useState} from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormControl from "@material-ui/core/FormControl";
import {Checkbox, FormControlLabel, Popper, TextField, Typography} from "@material-ui/core";
import {Autocomplete, createFilterOptions} from '@material-ui/lab';

const useStyles = makeStyles(() => ({
    formControl: {
        minWidth: 250,
        maxWidth: 250,
    },
    popper: {
        maxWidth: "fit-content"
    }
}));

const PopperMy = (props) => {
    return <Popper {...props} style={useStyles.popper} placement="bottom-start"/>;
};

const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 250
});

const AutocompleatableSelect = (props) => {
    const classes = useStyles();
    const {label, options, selectedOptions, setSelectedOptions, noOptionsText} = props;
    const [inputValue, setInputValue] = useState("");

    useEffect(() => {

    }, [selectedOptions])
    return (
        <React.Fragment>
            <FormControl
                className={classes.formControl}>
                <Autocomplete
                    filterOptions={filterOptions}
                    multiple
                    {...(noOptionsText && {noOptionsText: noOptionsText})}
                    value={selectedOptions}
                    clearOnBlur
                    disableCloseOnSelect
                    PopperComponent={PopperMy}
                    options={options}
                    inputValue={inputValue}
                    getOptionLabel={(option) => option}
                    renderOption={(props, option, state) => (
                        <FormControlLabel
                            control={<Checkbox
                                style={{color: 'green', margin: 0, padding: 0}}
                                checked={option.selected}
                            />}
                            label={props}
                        />
                    )}
                    onChange={(e, value, reason) => {
                        setSelectedOptions(value);
                    }}
                    renderTags={selected => {
                        let renderTagsValue = selected.join(",");
                        return (
                            <Typography
                                noWrap={true}
                                color="textPrimary"
                                variant="body1"
                                style={{maxWidth: 150}}>
                                {renderTagsValue}
                            </Typography>
                        );
                    }} renderInput={(params) => {
                    return <TextField
                        {...params}
                        label={label}
                        onChange={e => setInputValue(e.target.value)}
                    />
                }}
                />
            </FormControl>
        </React.Fragment>
    );
}

export default AutocompleatableSelect;
